
import VueSlickCarousel from 'vue-slick-carousel';

import UnitedSlide from './UnitedSlide.vue';
import ServiceSlide from './ServiceSlide.vue';
import FashionSlide from './FashionSlide.vue';
import LandingIntroTextSlide from './LandingIntroTextSlide.vue';
import IconChevronBig from '~/components/icons/IconChevronBig.vue';
import 'vue-slick-carousel/dist/vue-slick-carousel.css';
import IconChevron from '~/components/icons/IconChevron.vue';

export default {
  components: { VueSlickCarousel, IconChevronBig, UnitedSlide, ServiceSlide, FashionSlide, LandingIntroTextSlide, IconChevron },
  data () {
    return {
      current: 0
    };
  },
  computed: {
    bigTextVisible () {
      return this.current <= 2;
    },
    controlColor () {
      return this.current <= 2 ? 'white' : 'black';
    }
  },
  methods: {
    nextSlide () {
      this.$refs.slideshow.next();
    },
    prevSlide () {
      this.$refs.slideshow.prev();
    },
    updateSlide (_o, n) {
      this.current = n;
    }
  }
};
